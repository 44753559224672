import axios from "axios"
import { useState } from "react"
import { useAppOptions } from "../useAppOptions"

export const useEvents = () => {


  const [points, setPoints] = useState()

  const getNearEvents = async (userLngLat) => {

    let fetchedEvents;
    const sortedEvents = []

    try {               
      const response = await axios.get(`${process.env.REACT_APP_API_URL}events/getPoints?lat_min=${userLngLat.latitude - 0.25}&lat_max=${userLngLat.latitude + 0.25}&lng_min=${userLngLat.longitude - 0.25}&lng_max=${userLngLat.longitude + 0.25}`)
      fetchedEvents = response.data
    } catch (error) {
      console.error(error);
    }

    for (const event of fetchedEvents) {
      const haversine = require("haversine-distance");
      //First point in your haversine calculation
      const point1 = { lat: event.lat, lng: event.lng }

      //Second point in your haversine calculation
      const point2 = { lat: userLngLat.latitude, lng: userLngLat.longitude }

      const haversine_km = haversine(point1, point2) / 1000; //Results in meters (default)
      if (haversine_km < 5) sortedEvents.push({title: event.id, id: event.id, lng: event.lng, lat: event.lat, dist: haversine_km.toFixed(2)})
    }

    function compareFunction(a,b){
      if(a.dist > b.dist)
        return 1;
      else
        return -1;
    }
    
    sortedEvents.sort(compareFunction);
    return sortedEvents
  } 

  const updatePoints = async (params) => {
    const {lngMax, lngMin, latMax, latMin} = params

    try {                       
      const response = await axios.get(`${process.env.REACT_APP_API_URL}events/getPoints?lat_min=${latMin}&lat_max=${latMax}&lng_min=${lngMin}&lng_max=${lngMax}`)
      setPoints(response.data)
      
    } catch (error) {
      console.error(error);
    }

  }

  const getEventsByParams = async (ids) => {

    let res;

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}events/getByIds?ids=${ids}`)
      res = response.data
    } catch (error) {
      console.error(error);
    }

    return res
  }

  const getEventById = async (id) => {
    let res;

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}events/getByIds?ids=${id}`)
      res = response.data
    } catch (error) {
      console.error(error);
    }

    return res
  }

  const calcDistance = (point) => {
    // if (!userPosition) return
    // const haversine = require("haversine-distance");
    // //First point in your haversine calculation
    // console.log('userPosition', userPosition)
    // const point1 = { lat: userPosition.lat, lng: userPosition.lng }

    // //Second point in your haversine calculation
    // const point2 = { lat: point.latitude, lng: point.longitude }

    // const haversine_km = haversine(point1, point2) / 1000; //Results in meters (default)
    


    // return `${haversine_km}km`
  }
 
  return {
    points,
    getEventsByParams,
    updatePoints,
    getNearEvents,
    getEventById,
    calcDistance,
  }

}