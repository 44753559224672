import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const useSignUpForms = () => {

  const [step, setStep] = useState(0)
  const [form, setForm] = useState()

  const stepDetect = () => {
    const locate = window.location.search
    const urlParams = new URLSearchParams(locate)

    const step = urlParams.get('step')

    switch (step) {

      case 'mail':
        setStep(1)
        break;

      case 'checkMail':
        setStep(2)
        break;

      case 'userData':
        setStep(3)
        break;

      case null:
        setStep(0)
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    stepDetect()
  }, [window.location.search]) 

  const params = useParams()
  const navigate = useNavigate()

  const [userData, setUserData] = useState({
    name: '',
    secondName: '',
    userName: '',
    password: '',
    ndPassword: '',
  })

  const startRegistration = (e) => {

    navigate('/auth?action=signUp&step=mail')
    setStep(prev => prev + 1)
    e.preventDefault()
  }

  const emailNameVerify = (e) => {
    navigate('/auth?action=signUp&step=checkMail')
    setStep(prev => prev + 1)
    e.preventDefault()
  }

  const emailCodeVerify = (e) => {
    navigate('/auth?action=signUp&step=userData')
    setStep(prev => prev + 1)
    e.preventDefault()
  }

  const accountDataVerify = (e) => {


    setStep(prev => prev + 1)
    e.preventDefault()
  }

  const onUserDataChange = (field, val) => {

    setUserData(prev => ({...prev, [field]: val}))
  }

  const changeAuthVariant = () => {
    navigate('/auth?action=signIn')
  }


  useEffect(() => {
    switch (step) {
      case 0:
        setForm(
          <div className='signUp-form-wrapper'>
            <div className='content'>
              <div className='text-wrapper'>
                <h1 className='text'>Регистрация</h1>
                <div className='reg-des-wrapper'>
                  <h1 className='text'>Выберете удобный способ регистрации</h1>
                </div>
              </div>
              <button onClick={startRegistration} className='log-pas-btn'>
                <h1 className='text'>Использовать логин и пароль</h1>
              </button>
              <div className='spacer'>
                <h1 className='text'>или используйте</h1>
              </div>
              <div className='other-log-variants'>
                <div className='item'>

                </div>
                <div className='item'>
                  
                </div>
                <div className='item'>
                
                </div>
              </div>
              <div className='change-auth-type'>
                <h1 className='text'>Уже есть аккаунт?</h1>
                <h1 onClick={changeAuthVariant} className='text underline'>Войти</h1>
              </div>
            </div>
          </div>
        )
        break;

      case 1:
        setForm(
          <form onSubmit={(e) => emailNameVerify(e)} id='signUp-email' className='signUp-form-wrapper'>
            <div className='content'>
              <div className='text-wrapper'>
                <h1 className='text'>Регистрация</h1>
                <div className='reg-des-wrapper'>
                  <h1 className='text'>Для начала давайте узнаем вашу почту</h1>
                </div>
              </div>
              <div className='input-wrapper'>
                <div className='input-box'>
                  <input type='email' placeholder='' required='required'></input>
                  <span>e-mail</span>
                </div>
              </div>
              <button className='log-pas-btn'>
                <h1 className='text'>Далее</h1>
              </button>
            </div>
          </form>
        )
        break;
        
      case 2:
        setForm(
          <form onSubmit={(e) => emailCodeVerify(e)} id='email-code-verification' className='signUp-form-wrapper'>
            <div className='content'>
              <div className='text-wrapper'>
                <h1 className='text'>Регистрация</h1>
                <div className='reg-des-wrapper'>
                  <h1 className='text'>Введите код который был отправлен вам на почту</h1>
                </div>
              </div>
              <div className='input-wrapper'>
                <div className='input-box'>
                  <input className='number-input-verification' type='number' placeholder='' required='required'></input>
                  <span>code</span>
                </div>
              </div>
              <button className='log-pas-btn'>
                <h1 className='text'>Далее</h1>
              </button>
            </div>
          </form>
        )
        break;

      case 3:
        setForm(
          <form id='account-data' className='signUp-form-wrapper'>
            <div className='content'>
              <div className='text-wrapper'>
                <h1 className='text'>Регистрация</h1>
                <div className='reg-des-wrapper'>
                  <h1 className='text'>Заполните данные о вас</h1>
                </div>
              </div>
              <div className='input-wrapper-verify'>
                <div className='input-box'>
                  <input defaultValue={''} onChange={(e) => onUserDataChange('name', e.nativeEvent.target.value)} id='input-name' type='text' placeholder='' required='required'></input>
                  <span>Имя</span>
                </div>
                <div className='input-box'>
                  <input defaultValue={''} onChange={(e) => onUserDataChange('secondName', e.nativeEvent.target.value)} id='input-second-name' type='text' placeholder='' required='required'></input>
                  <span>Фамилия</span>
                </div>
                <div className='input-box'>
                  <input defaultValue={''} onChange={(e) => onUserDataChange('userName', e.nativeEvent.target.value)} id='input-user-name' type='text' placeholder='' required='required'></input>
                  <span>Имя на сайте</span>
                </div>
                <div className='input-box'>
                  <input defaultValue={''} onChange={(e) => onUserDataChange('password', e.nativeEvent.target.value)} id='input-password' type='password' placeholder='' required='required'></input>
                  <span>Пароль</span>
                </div>
                <div className='input-box'>
                  <input defaultValue={''} onChange={(e) => onUserDataChange('ndPassword', e.nativeEvent.target.value)} id='input-nd-password' type='password' placeholder='' required='required'></input>
                  <span>Повторите пароль</span>
                </div>
              </div>
              <button onClick={(e) => accountDataVerify(e)} className='log-pas-btn'>
                <h1 className='text'>Далее</h1>
              </button>
            </div>
          </form>
        )
        break;
    
      default:
        break;
    }
  }, [step])


  return {
    form, 
  }
}