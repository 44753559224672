import React, { useEffect } from 'react'
import './searchTab.css'
import { useSearchParams } from '../searchTab/useSearchParams'
import EventCard from '../../miniComponents/eventCard/eventCard.jsx'

function SearchTab({selectedEvent, setSelectedEvent}) {


  const {suggestions, handleChangeSelector } = useSearchParams()

  return (
    <div className='search-tab'>
      <div className='selector-wrapper'>
        <div className='selector '>
          <div onClick={() => handleChangeSelector('events')} id='events-selector-btn' className='btn selected'>
            <h1 className='text'>Мероприятия</h1>
          </div>
          <div onClick={() => handleChangeSelector('places')} id='places-selector-btn' className='btn'>
            <h1 className='text'>Места</h1>
          </div>
          <div id='selector-thumb' className='thumb'/>
        </div>
      </div>
      <div className='suggestions-wrapper'>
        {true?
        <div className='render-suggestions'>
          {suggestions.length === 0 ?
          <div>
            
          </div>
          :
          <div>
            {suggestions.slice(0, 20).map((item) => {
              return (
                <EventCard selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} key={crypto.randomUUID()} event={item} size={'large'}/>
              )
            })}
          </div>
          }
        </div>
        :
        <div className='intro-of-search'>
        
        </div>
        }
      </div>
    </div>
  )
}

export default SearchTab