import React, { useEffect, useState } from 'react'
import './menu.css'
import { ReactComponent as Arrow } from '../../assets/SVGs/ArrowRightOutLine.svg'
import { useNavOptions } from '../navBar/useNavOptions'
import { useMenuOptions } from './useMenuOptions'
import SearchInput from '../searchInput/searchInput'
import { useEvents } from '../../globalHooks/useEvents'
import EventTab from '../eventTab/eventTab'

function Menu({setMenuWidth, menuWidth, selectedEvent, setSelectedEvent}) {

  const { tab, selectedName } = useNavOptions({selectedEvent, setSelectedEvent})
  const {  } = useMenuOptions({setMenuWidth})
  const [event, setEvent] = useState()
  const { getEventById } = useEvents()
  const [eventId, setEventId] = useState()

  useEffect(() => {
    const eventBox = document.getElementById('event-box-wrapper')
    if (selectedEvent === undefined) return eventBox.classList.remove('open')
    eventBox.classList.add('open')
    selectedEvent.hasOwnProperty('properties') ? setEventId(selectedEvent.properties.id) : setEventId(selectedEvent.id)

  }, [selectedEvent])

  useEffect(() => {
    if (!eventId) return 
    getEventById(eventId).then(val => setEvent(val[0]))
  }, [eventId])

  return (
    <>
      <div id='menu-box' className='menu-wrapper'>
        <div className='content'>
          {selectedName.title !== 'profile' ?
            <SearchInput width={menuWidth}/>
            :
            <></>
          }
          {tab}
        </div>
        <div id='close-menu-btn'className='close-btn'>
        <Arrow className='icon'/>
        </div>
      </div>
      <EventTab event={event} menuWidth={menuWidth}/>
    </>
  )
}

export default Menu