import React, { useEffect, useState } from 'react'
import './userLocate.css'
import { useUserLocate } from './userLocate'
import { MoonLoader } from 'react-spinners'


function UserLocate({menuWidth, setCameraPosition, map, mapboxgl, cameraPosition, userPosition}) {

  const { changeZoom, findMe, btnState, handleClick } = useUserLocate({setCameraPosition, map, mapboxgl, cameraPosition, userPosition})

  return (
    <div className='locate-me-btn' style={{'--ml': `${menuWidth}px`}}>
      {btnState?
        <div className='btn' >
          <div className='icon'>
            <div className='spinner'>
              <MoonLoader size={20} color='#fff' className='spinner-icon'/>
            </div>
          </div>
        </div>
        :
        <div onClick={() => handleClick()} id='me-btn' className='btn'>
          <div className='icon'>
            <h1 className='text'>me</h1>
          </div>
        </div>
      }
      <div onClick={() => changeZoom('plus')} className='btn' >
        <div className='text'>
          +
        </div>
      </div>
      <div onClick={() => changeZoom('minus')} className='btn' >
        <div className='text'>
          -
        </div>
      </div>
    </div>
  )
}

export default UserLocate