import React, { useEffect } from "react";
import './dateRangeSelector.css'
import { ReactComponent as Arrow } from '../../../assets/SVGs/ArrowRightOutLine.svg'
import { useDateRangeSelector } from "./useDateRange";



function DateRangeSelector() {

  const { date, handleNextDay, handlePrevDay } = useDateRangeSelector()
  

  return (
    <div className="date-selector">
      <div onClick={handlePrevDay} className="arrow left">
        <Arrow className="icon left"/>
      </div>
      <div className="date">
        <h1 className="text">{date}</h1>
      </div>
      <div onClick={handleNextDay} className="arrow right">
        <Arrow className="icon"/>
      </div>
    </div>
  );
}

export default DateRangeSelector