import React from 'react'
import './friendsPlan.css'
import { ReactComponent as ArrowDown } from '../../../../assets/SVGs/ArrowDown.svg'

function FriendsPlan() {

  const events = [1, 2, 3, 4]

  return (
    <div className='friends-plan-wrapper'>
      <div className='header'>
        <h1 className='text'>у ваших друзей в планах на</h1>
        <div className='drop-down'>
          <h1 className='text'>сегодня</h1>
          <ArrowDown className='arrow'/>
        </div>
      </div>
      <div className='list'>
        {events.slice(1, 4).map((item) => {
          return (
            <div key={crypto.randomUUID()} className='item'>
              <h1 className='title'>BIG EvenTracker party</h1>
              <h1 className='time'>16:30-18:30</h1>
            </div>
          )
        })}
      </div>
      <div className='more-btn'>
        <h1 className='text'>и ещё 3</h1>
      </div>
    </div>
  )
}

export default FriendsPlan