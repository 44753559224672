import React, { useEffect } from 'react'
import './feedTab.css'
import BlockOfContentTitle from '../../miniComponents/blockOfContentTitle/blockOfContentTitle'
import ImageSwiper from './imageSwiper/imageSwiper'
import FriendsEventsBanner from './friendsEvents/friendsEventsBanner.jsx'
import EventCard from '../../miniComponents/eventCard/eventCard.jsx'
import FriendsPlan from './friendsPlan/friendsPlan.jsx'
import ActivityTypeCard from '../../miniComponents/activityTypeCard/activityTypeCard.jsx'
import { useFeedTabOptions } from './useFeedTabOptions.js'


function FeedTab() {

  const {data} = useFeedTabOptions()

  return (
    <div className='tab-wrapper'>
      <BlockOfContentTitle title={'Что то намечается'} arrow={true}/>
      <ImageSwiper events={data?.toSwipe}/>
      <BlockOfContentTitle title={'Чем заняться'} arrow={false}/>
      <FriendsEventsBanner/>
      <div className='ads-block-events'>
        <EventCard event={data?.ads[0]} type={'ads'} size={'small'} />
        <EventCard event={data?.ads[1]} type={'ads'} size={'small'} />
      </div>
      <FriendsPlan/>
      <div className='active-cards'>
        <ActivityTypeCard text={'Активный отдых'} type={'active'}/>
        <ActivityTypeCard text={'Спокойный отдых'} type={'basic'}/>
        <ActivityTypeCard text={'Умеренный отдых'} type={'passive'}/>
        <ActivityTypeCard text={'Все виды'} type={'more'}/>
      </div>
    </div>
  )
}

export default FeedTab