import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import './map.css'
import "mapbox-gl/dist/mapbox-gl.css";
import { useMapOptions } from './useMapOptions.js'
import MapUIControl from "../mapUIControl/mapUIControl.jsx";


const Map = ({menuWidth, userPosition, appParams, setLoad, theme, selectedEvent, setSelectedEvent}) => {

  const mapContainerRef = useRef();
  const mapRef = useRef();
  const { mapHeight, setMap, points, setLat, setLng, goTo, setCameraPosition, cameraPosition } = useMapOptions({menuWidth, userPosition, setSelectedEvent});
  const [maxZoom, setMaxZoom] = useState(9.5)

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      bearing: 0,
      language: 'ru-RU',
      pitch: 30,
      style: theme? 'mapbox://styles/dmitriyv2044/cm1tak377007901p95znw3z3o' : 'mapbox://styles/dmitriyv2044/cm0wfsxos00yd01qydikahqem',
      center: [37.617441, 55.750404], // starting position [lng, lat]
      zoom: 15.2, // starting zoom
      minZoom: maxZoom,
    });
  
    mapRef.current = map
    map.on('load', () => {
      setMap(map)
      setCameraPosition({lng: 37.617441, lat: 55.750404})
      setLoad(prevState => ({...prevState, map: false}));
    })
  }, [])

  useEffect(() => {
    if (points === undefined) return
    setTimeout(() => {
      setLoad(prevState => ({...prevState, content: false}));
    }, 250);
  }, [points])

  const [menuContentWidth, setMenuContentWidth] = useState(500)

  useEffect(() => {
    if (selectedEvent === undefined) return setMenuContentWidth(menuWidth)
      setMenuContentWidth(menuWidth + 410)
  }, [selectedEvent, menuWidth])
  
  return (
    <>
      <MapUIControl mapboxgl={mapboxgl} points={points} setCameraPosition={setCameraPosition} menuWidth={menuContentWidth} map={mapRef.current} userPosition={userPosition} cameraPosition={cameraPosition}/>
      <div className="map-wrpaper" style={{'--mapWidth': `${menuWidth}px`}}>
        <div
          style={{height: mapHeight}}
          ref={mapContainerRef}
          className="map-container"
        />
      </div>
      {appParams.includes('lngLatZoom') ? 
        <div className="dev-map-move" style={{'--ml': `${menuWidth}px`}}>
          <input onChange={(e) => setLng(e.currentTarget.value)} id='map-move-lng' alt="" placeholder="lng"/>
          <input onChange={(e) => setLat(e.currentTarget.value)} id='map-move-lat' alt="" placeholder="lat"/>
          <input onChange={(e) => setMaxZoom(e.currentTarget.value)} id='set-zoom' alt="" placeholder="zoom"/>
          <button onClick={() => goTo()}>
            go
          </button>
        </div>
        :
        <></>
      }
    </>

  );
};

export default Map;