import React, { useEffect, useState } from 'react'
import './signIn.css'
import waveRight from '../../../assets/IMGs/WaveRIght.png'
import { useSignInForms } from './useSignInForms'


function SignIn() {

  const {form} = useSignInForms()

  return (
    <div id='signIn' className='signIn-wrapper'>
      <div className='hello-box'>
        <div className='wave-wrapper-right'>
          <img src={waveRight} className='wave'/>
        </div>
      </div>
      <div className='signIn'>
        {form}
      </div>
    </div>
  )
}

export default SignIn