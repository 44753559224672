import { Outlet, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import './index.css'
import NavBar from "./components/navBar/navBar.jsx";
import MainPage from "./mainScreen.jsx";

export default function MainScreenLayout({children}) {

  const [theme, setTheme] = useState(true)

  return(
    <div className="app-wrapper">
      <MainPage theme={theme}/>
      <NavBar setTheme={setTheme} theme={theme}/>
    </div>
  )


}