import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

export const useSignInForms = () => {

  const [step, setStep] = useState(0)
  const [form, setForm] = useState()

  const stepDetect = () => {
    const locate = window.location.search
    const urlParams = new URLSearchParams(locate)

    const step = urlParams.get('step')

    switch (step) {

      case '2FA':
        setStep(1)
        break;

      case null:
        setStep(0)
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    stepDetect()
  }, [window.location.search]) 

  const params = useParams()
  const navigate = useNavigate()

  const checkSignInData = (e) => {
    e.preventDefault()
    alert('authorized')
  }

  const changeAuthVariant = () => {
    navigate('/auth?action=signUp')
  }

  useEffect(() => {

    switch (step) {
      case 0:
        setForm(
          <form onSubmit={(e) => checkSignInData(e)} id='sign-in-form' className='signIn-form-wrapper'>
            <div className='content'>
              <div className='text-wrapper'>
                <h1 className='text'>Вход</h1>
                <div className='reg-des-wrapper'>
                  <h1 className='text'>Введите данные от вашего аккаунта</h1>
                </div>
              </div>
              <div className='input-wrapper'>
                <div className='input-box'>
                  <input className='login' name="login" id="login-input" type='text' placeholder='' required='required'></input>
                  <span>Логин</span>
                </div>
              </div>
              <div className='input-wrapper'>
                <div className='input-box'>
                  <input className='password' name="login" id="password-input" type='password' placeholder='' required='required'></input>
                  <span>Пароль</span>
                </div>
              </div>
              <button className='log-pas-btn'>
                <h1 className='text'>Далее</h1>
              </button>
              <div className='spacer'>
                <h1 className='text'>или используйте</h1>
              </div>
              <div className='other-log-variants'>
                <div className='item'>

                </div>
                <div className='item'>
                  
                </div>
                <div className='item'>
                
                </div>
              </div>
              <div className='change-auth-type'>
                <h1 className='text'>Нет аккаунта?</h1>
                <h1 onClick={changeAuthVariant} className='text underline'>Регистрация</h1>
              </div>
            </div>
          </form>
        )
        break;

      case 1:
        
        break;
    
      default:
        break;
    }

  }, [step])

  return {
    form,
  }
}