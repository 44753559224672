import { useAsyncError, useNavigate, useRouteError } from "react-router-dom";
import './404errorPage.css'
import WavesTop from '../../assets/IMGs/WavesTop.png'
import WavesBottom from '../../assets/IMGs/WavesBottom.png'

export default function ErrorPage() {

  const navigate = useNavigate()

  return (
    <div id="error-page" className="error-page-wrapper">
      <div className="header">
        <h1 className="text">Хм, странно но такой страницы нету</h1>
        <button onClick={() => navigate('/')} className="btn">
          <h1 className="text">На главную</h1>
        </button>
        <i className="error-reason">error: 404 NotFound {window.location.pathname}</i>
      </div>
      <img className="image-bg top" src={WavesTop} alt=""/>
      <img className="image-bg bottom" src={WavesBottom} alt=""/>
      
    </div>
  );
}