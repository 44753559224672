import React, { useEffect, useState } from 'react'
import './eventCard.css'
import { HashLoader } from 'react-spinners'
import ActiveIcon from '../../../assets/IMGs/ActiveIcon.png'
import ClubIcon from '../../../assets/IMGs/ClubIcon.png'
import Basic from '../../../assets/IMGs/BasicIcon.png'
import Passive from '../../../assets/IMGs/PassiveIcon.png'

function EventCard({event, type, size, selectedEvent, setSelectedEvent}) {

  const [card, setCard] = useState()

  const handleClick = () => {
    setSelectedEvent(undefined)
    setTimeout(() => {
      setSelectedEvent({id: event.id})
    }, 250);  
  }

  const selectIcon = (type) => {

    switch (type) {
      case 'party':
        return <img src={ClubIcon} alt=''/>

      case 'passive':
        return <img src={Passive} alt=''/>

      case 'active':
        return <img src={ActiveIcon} alt=''/>

      case 'edu':

        return <img src={Basic} alt=''/>

    
      default:
        return <img src={Passive} alt=''/>
    }

  }

  useEffect(() => {
    switch (size) {
      case 'small':
        setCard(
          <div className='small-event-card'>
            {event !== undefined?
            <>
              <div className='content-box'>
                <h1 className='title'>{event.name}</h1>
                {
                  type === 'ads' ?
                  <div className='ads'>
                    <h1 className='text'>Реклама</h1>
                  </div>
                  :
                  <></>
                }
              </div>
              <div className='org-icon'>
                <img src={event.img} className='icon'/>
              </div>
            </>
            :
            <HashLoader color='#6483FF' size={15}/>
            }
          </div>
        )
        break;
  
      case 'medium':
        setCard(
          <div className='medium-event-card'>
            
          </div>
        )
        break;
  
      case 'large':
        setCard(
          <div onClick={(e) => handleClick()} className='large-event-card'>
            {event !== undefined?
            <>
              <div className='content-card-wrapper'>
                <div className='image-wrapper'>
                  <img src={event.img}/>
                </div>
                <div className='btn-add-to-plans'>

                </div>
                <div className='fav-params'>

                </div>
                <div className='content-text'>
                  <div className='about'>
                    <h1 className='name'>{event.name}</h1>
                    <h1 className='address'>{event.address}</h1>
                  </div>
                  <div className='dist'>
                    {selectIcon(event.type)}
                  </div>
                </div>
              </div>
            </>
            :
            <HashLoader color='#6483FF' size={15}/>
            }
          </div>
        )
        break;
    
      default:
        break;
    }  
  }, [event])

  return (
    <>
      {card}
    </>
  )
}

export default EventCard