import React, { useEffect, useState } from 'react'
import './nearEvents.css'
import { useNearEvents } from './useNearEvents'
import { HashLoader } from 'react-spinners'
import { ReactComponent as NoGeo } from '../../../assets/SVGs/NoGeoLocation.svg'

function NearEvents({setCameraPosition, userPosition}) {

  const { current, renderEvents, change, nearEvents, goToCurrentPoint } = useNearEvents({setCameraPosition})
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (userPosition === undefined) return
    renderEvents(userPosition)
    setCount(0)
  }, [userPosition])

  useEffect(() => {
    if (current === undefined) return
    if (count === 0) return
    setCameraPosition({lng: current.lng, lat: current.lat, event: true, data: current})
  }, [current])


  const [geo, setGeo] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setGeo(false)
    }, 5000);

    if (nearEvents) clearTimeout(timer)
  }, [nearEvents])


  return (
  <>
    <div className='near-events-box load'>
      <div className='near-events-wrapper'>
        {nearEvents?.length === 0 ?
        <div className='near-event-none'>
          <h1 className='text'>ближайшие мероприятия не найдены</h1>
        </div>
        :
        <>
        <div onClick={() => goToCurrentPoint()} className='near-events' style={{backgroundImage: 'url(https://eventracker.fileapi.ru/data/events/event1.png)', backgroundAttachment: 'center', backgroundSize: 'cover'}}>
          <div className='content-box' >
            <h1 className='event-title'>{current?.title}</h1>
            <h1 className='event-dist'>{current?.dist}km</h1>
          </div>
        </div>
        <div id='near-go-btn' className='go-btn-wrapper'>
          <div onClick={() => goToCurrentPoint()} className='go-btn'>
            <h1 className='go-text'>перейти</h1>
          </div>
        </div>
        </>
        }
      </div>
      <div id='near-btns-group' className='btns-group'>
        <div onClick={() => count === 0 ? <></> : [change(count - 1, nearEvents), setCount(count - 1)]} className='btn prev'>
          назад
        </div>
        <div onClick={() => count === nearEvents.length - 1 ? <></> : [change(count + 1, nearEvents), setCount(count + 1)]} className='btn next'>
          вперед
        </div>
      </div>
    </div>
    <div className='loading'>
      {geo?
      <HashLoader color='#6483FF' size={20}/>
      :
      <NoGeo/>
      }
    </div>
  </>
  )
}

export default NearEvents