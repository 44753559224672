import React, { useEffect, useRef } from 'react'
import './imageSwiper.css'
import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import { HashLoader } from 'react-spinners';

function ImageSwiper({events}) {
  

  function convertDate (timestamp) {
    const today = new Date(timestamp);
    const day = new Date(timestamp);
    day.setDate(today.getDate());

    const options = {
      day: 'numeric',
      month: 'long',
    };

    const hours = new Date(timestamp).getHours()
    const minutes = new Date(timestamp).getMinutes()

    return `${day.toLocaleDateString('ru-GB', options)}, в ${hours}:${minutes} `
  }

  
  return (
    events?
      <Swiper autoplay={{delay: 5000, disableOnInteraction: false}} pagination={{clickable: true}} modules={[Autoplay, Pagination]} className="mySwiper">
        {events.map((item, index) => {
          return (
            <SwiperSlide key={crypto.randomUUID()}>
              <div className='content-box'>
                <h1 className='text'>{item.description}</h1>
                <h1 className='text-date'>{convertDate(item.timestamp_start)}</h1>
              </div>
              <img src={item.img} alt=''/>
            </SwiperSlide>
          )
        })}
      </Swiper>
      :
      <div className='swiper'>
        <HashLoader className='loader' color='#6483FF' size={25}/>
      </div>
)
}

export default ImageSwiper