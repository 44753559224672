import React, { useState } from 'react'
import UserLocate from './userLocate/userLocate.jsx'
import './mapUIControl.css'
import NearEvents from './nearEvents/nearEvents.jsx'
import DateRangeSelector from './dateRangeSelector/dateRangeSelector.jsx'
import { ReactComponent as Arrow } from '../../assets/SVGs/ArrowRightOutLine.svg'


function MapUIControl({mapboxgl, points, setCameraPosition, menuWidth, map, userPosition, cameraPosition}) {

  const [t, setT] = useState(30)

  const handleClick = (e) => {
    const box = document.querySelector('.ui-control')
    box.classList.toggle('hide')
    const h = (box.getBoundingClientRect().height - 45) * -1 
    if (box.classList.contains('hide')) {
      setT(h)
    } else {
      setT(30)
    }
  }

  return (
    <>
    <div className='ui-control' style={{'--t': `${t}px`}}>
      <DateRangeSelector/>
      <NearEvents points={points} setCameraPosition={setCameraPosition} cameraPosition={cameraPosition} userPosition={userPosition} map={map} mapboxgl={mapboxgl}/>
      <div onClick={(e) => handleClick(e)} className='ui-control-close-btn'>
        <button className='btn'>
          <Arrow className='icon'/>
        </button>
      </div>
    </div>
    <UserLocate menuWidth={menuWidth} cameraPosition={cameraPosition} userPosition={userPosition} setCameraPosition={setCameraPosition} map={map} mapboxgl={mapboxgl}/>
    </>
  )
}

export default MapUIControl