import { useEffect, useRef, useState } from "react"
import { useEvents } from "../../../globalHooks/useEvents"


export const useNearEvents = ({setCameraPosition}) => {

  const [nearEvents, setNearEvents] = useState()
  const [current, setCurrent] = useState()
  const { getNearEvents } = useEvents()
  const [nextEventLoad, setNextEventLoad] = useState(false)

  const renderEvents = (user) => {
    getNearEvents(user).then((val) => setNearEvents(val))
  }

  const renderCurrentEvent = (cnt, points) => {
    const event = points[cnt]
    setCurrent(event)
  }

  useEffect(() => {
    if (nearEvents === undefined) return

    renderCurrentEvent(0, nearEvents)

    document.querySelector('.near-events-box').classList.remove('load')
    document.querySelector('.loading').classList.add('load')

  }, [nearEvents])

  useEffect(() => {
    const prev = document.querySelector('.btns-group .prev')
    const next = document.querySelector('.btns-group .next')

    const touchPrev = () => {
      prev.classList.toggle('main')
      next.classList.toggle('stock')
    }

    const touchNext = () => {
      next.classList.toggle('main')
      prev.classList.toggle('stock')

    }

    prev.addEventListener('mouseover', () => touchPrev())
    prev.addEventListener('mouseout', () => touchPrev())
    next.addEventListener('mouseover', () => touchNext())
    next.addEventListener('mouseout', () => touchNext())

    return () => {
      prev.removeEventListener('mouseover', () => touchPrev())
      prev.removeEventListener('mouseout', () => touchPrev())
      next.removeEventListener('mouseover', () => touchNext())
      next.removeEventListener('mouseout', () => touchNext())
    }
  }, [])


  const change = (cnt, points) => {
    renderCurrentEvent(cnt, points, false)
  }

  const goToCurrentPoint = () => {
    setCameraPosition({lng: current.lng, lat: current.lat, event: true, data: current})
  }

  return {
    current,
    renderEvents,
    change,
    nearEvents,
    goToCurrentPoint,
    nextEventLoad,
  }
}