import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import './authPage.css'
import SignIn from './signIn/signIn.jsx'
import SignUp from './signUp/signUp.jsx'
import logo from '../../assets/IMGs/Favicon.png'

function AuthPage() {

  const navigate = useNavigate()

  const [action, setAction] = useState()

  useEffect(() => {
    const locate = window.location.search
    const urlParams = new URLSearchParams(locate)
    setAction(urlParams.get('action'))
  }, [window.location.search])

  const changeAuthVariant = (type) => {
    const signUp = document.getElementById('signUp')
    const signIn = document.getElementById('signIn')

    if (type === 'signUp') {
      signUp.classList.remove('hide')
      signIn.classList.add('hide')
    } else {
      signUp.classList.add('hide')
      signIn.classList.remove('hide')
    }

  }

  const changeAction = (urlParams) => {
    const action = urlParams.get('action')
    if (action === 'signUp' || action === 'signIn') {
      return action 
    } else {
      navigate('/auth?action=signUp')
      return 'signUp'
    }
  }

  const authDoneDetect = () => {
    const locate = window.location.search
    const urlParams = new URLSearchParams(locate)

    return (
      navigate(locate),
      changeAuthVariant(changeAction(urlParams))
    )
  }

  useEffect(() => {
    if (!action) return authDoneDetect()
    
    switch (action) {
      case 'signUp':
        changeAuthVariant('signUp')
        break;

      case 'signIn':
        changeAuthVariant('signIn')
        break;
    
      default:
        
        break;
    }
  }, [action])

  return (
    <div className='auth-page-wrapper'>
      <div className='tracker-logo'>
        <div className='text-wrapper'>
          <h1 className='text'>EvenTracker</h1>
        </div>
        <div className='icon-wrapper'>
          <img className='icon' src={logo} alt=''/>
        </div>
      </div>
      <SignIn/>
      <SignUp/>
    </div>
  )
}

export default AuthPage