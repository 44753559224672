import { useEffect, useState } from "react"



export const useSearch = () => {
  const [state, setState] = useState()



  return {

  }
}