import { useEffect, useState } from "react"
import { useEvents } from "../../../globalHooks/useEvents"



export const useFeedTabOptions = () => {

  const [data, setData] = useState({toSwipe: undefined, ads: []})
  const { getEventsByParams } = useEvents()

  const getFeedTabInitData = async () => {

    let res = {toSwipe: undefined, ads: undefined}

    const events = await getEventsByParams([560, 720])
    res.toSwipe = events
    res.ads = events

    setData(res)
  }


  useEffect(() => {
    getFeedTabInitData()
  }, [])


  return {
    data
  }
}