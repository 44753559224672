import { useEffect, useState } from "react"
import { useEvents } from "../../globalHooks/useEvents"

export const useMenuOptions = ({setMenuWidth}) => {

  const [state, setState] = useState()
  const { getEventsByParams } = useEvents()
  



  useEffect(() => {
    const btn = document.getElementById('close-menu-btn')
    const menu = document.getElementById('menu-box')

    const handleClose = () => {
      if (!menu.classList.contains('hide')) {
        menu.classList.add('hide')
        btn.classList.add('hide')
        setMenuWidth(80)
      } else {
        menu.classList.remove('hide')
        btn.classList.remove('hide')
        setMenuWidth(500)
      }
    }

    btn.addEventListener('click', handleClose)


    return () => {
      btn.removeEventListener('click', handleClose)
    }

  }, [])

  return {
    state,
  }
  
}