import { useEffect, useState } from "react"

export const useDateRangeSelector = () => {


  const [color, setColor] = useState('var(--main-text-accented)')
  const [date, setDate] = useState()
  const [count, setCount] = useState(0)

  function addDays(days) {
    const today = new Date();
    const day = new Date();
    day.setDate(today.getDate() + days);

    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    };

    return day.toLocaleDateString('ru-GB', options).toUpperCase()
  }

  const handleNextDay = () => {
    setDate(addDays(count + 1))
    setCount(count + 1)
  }

  const handlePrevDay = () => {
    setDate(addDays(count - 1))
    setCount(count - 1)
  }
  

  useEffect(() => {
    setDate(addDays(false, 0))
  }, [])

  return {
    color,
    date,
    handleNextDay,
    handlePrevDay,
  }
}