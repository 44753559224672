import React from 'react'
import './friendsEventsBanner.css'
import friendsIcon from '../../../../assets/IMGs/FriendsIcon.png'

function FriendsEventsBanner() {
  return (
    <div className='friends-events-banner-wrapper'>
      <div className='text-box'>
        <h1 className='title'>Куда ходят ваши друзья ?</h1>
        <h2 className='des'>смотрите все мероприятия на карте</h2>
      </div>
      <div className='friends-icon'>
        <img src={friendsIcon} alt='' className='icon'/>
      </div>
    </div>
  )
}

export default FriendsEventsBanner