import React, { useEffect, useState } from 'react'
import './searchInput.css'
import { ReactComponent as SearchIcon } from '../../assets/SVGs/IconSearch.svg'
import { ReactComponent as FiltersIcon } from '../../assets/SVGs/FilersIcon.svg'
import { useSearch } from './useSearch'



function SearchInput({menuWidth}) {

  const { goToSearch } = useSearch()

  const [val, setVal] = useState(undefined)
  const [defVal, setDefVal] = useState()

  useEffect(() => {
    if (!val) return

    const timer = setTimeout(() => {
      const locate = window.location.search
      const urlParams = new URLSearchParams(locate)
  
      urlParams.set('search', val)
  
      const url = `?${urlParams.toString()}`
  
      window.history.pushState({}, '', url)
    }, 750);
    
    return () => {
      clearTimeout(timer)
    }
  }, [val])

  useEffect(() => {
    const locate = window.location.search
    const urlParams = new URLSearchParams(locate)
    setVal(urlParams.get('search'))
  }, [])

  const onChange = (e) => {
    setVal(e.target.value)
  }

  return (
    <div className='search-input-wrapper' style={{'--w': `${menuWidth}px`}}>
      <div className='input-box'>
        <div className='icon-wrapper'>
          <SearchIcon className='icon'/>
        </div>
        <input value={val || ''} onChange={(e) => onChange(e)} defaultValue={defVal} onClick={goToSearch} id='search-input-main' className='input' placeholder='Поиск'/>
        <div className='icon-wrapper'>
          <FiltersIcon className='icon'/>
        </div>
      </div>
    </div>
  )
}

export default SearchInput