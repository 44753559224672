import React, { useEffect } from 'react'
import './navBar.css'
import { ReactComponent as SearchIcon } from '../../assets/SVGs/SearchIconNav.svg'
import { ReactComponent as ProfileIcon } from '../../assets/SVGs/ProfileIconNav.svg'
import { ReactComponent as FeedIcon } from '../../assets/SVGs/FeedIconNav.svg'
import { useNavOptions } from './useNavOptions'
import { ReactComponent as DarkThemeIcon } from '../../assets/SVGs/DarkThemeIcon.svg'
import { ReactComponent as LightThemeIcon } from '../../assets/SVGs/LightThemeIcon.svg'

function NavBar({setTheme, theme}) {

  useEffect(() => {
    const dark = document.getElementById('dark-icon')
    const light = document.getElementById('light-icon')

    dark.addEventListener('click', () => {
      dark.classList.add('hide')
      light.classList.remove('hide')
    })

    light.addEventListener('click', () => {
      light.classList.add('hide')
      dark.classList.remove('hide')
    })

  }, [])

  useEffect(() => {
    theme? document.documentElement.classList.remove('light-theme') : document.documentElement.classList.add('light-theme')
  }, [theme])

  useEffect(() => {
    const locate = window.location.search
    const tab = new URLSearchParams(locate).get('tab')
    document.getElementById(`${tab}-btn`).classList.add('selected')
  }, [])

  return (
    <div className='navBar-wrpapper'>
      <div className='profile'>
        <img className='img' src={`https://storage.fileapi.ru/dvAvatar.JPG`} alt=''/>
      </div>
      <div className='nav-btn-group'>
        <div id='search-btn' name='search' className='btn '>
          <SearchIcon className='icon'/>
        </div>
        <div id='feed-btn' name='feed' className='btn'>
          <FeedIcon className='icon'/>
        </div>
        <div id='profile-btn' name='profile' className='btn'>
          <ProfileIcon className='icon'/>
        </div>
      </div>
      <div onClick={() => setTheme(!theme)} className='theme-btn'>
        <div id='dark-icon' className='icon '>
          <DarkThemeIcon width={35} height={35}/>
        </div>
        <div id='light-icon' className='icon hide'>
          <LightThemeIcon width={35} height={35}/>
        </div>
      </div>
    </div>
  )
}

export default NavBar