import axios from "axios"
import { useEffect, useState } from "react"
import { useQuery, useQueryClient, QueryClientProvider } from "react-query"

export const useSearchParams = () => {


  const [suggestions, setSuggestions] = useState([])

  const configureSearchRequestUrl = (urlParams) => {

    const searchQuery = urlParams.get('search')

    return `${process.env.REACT_APP_API_URL}events/suggest?q=${searchQuery}&params=name`
  }

  const searchRequest = async (type) => {
    const locate = window.location.search
    const urlParams = new URLSearchParams(locate)

    const response =  await axios.get(configureSearchRequestUrl(urlParams))

    if (type === 'handle') {
      setSuggestions(response.data)
    }

    return response 
  }

  const { isLoading, data, isError, error, isFetching } = useQuery('suggestions', searchRequest)

  const [prevData, setPrevData] = useState()

  useEffect(() => {
    if (!data) return
    if (JSON.stringify(prevData) === JSON.stringify(data.data)) return
    setPrevData(data.data)
    setSuggestions(data.data)
  }, [data])


  const handleChangeSelector = (type) => {
    const eventsBtn = document.getElementById('events-selector-btn')
    const placesBtn = document.getElementById('places-selector-btn')
    const thumb = document.getElementById('selector-thumb')

    if (type === 'events') {
      eventsBtn.classList.add('selected')
      placesBtn.classList.remove('selected')
      thumb.classList.remove('changed')
    } else {
      eventsBtn.classList.remove('selected')
      placesBtn.classList.add('selected')
      thumb.classList.add('changed')
    }
  }
 
  const [search, setSearch] = useState(false)

  useEffect(() => {
    const input = document.getElementById('search-input-main')

    const onChange = (e) => {
      setSearch(crypto.randomUUID())
    }

    input.addEventListener('input', onChange)


    return () => {
      input.removeEventListener('input', onChange)
    }

  }, [window.location.search])
  
  useEffect(() => {
    if (search === false) return
    const timer = setTimeout(() => {  
      searchRequest('handle')
    }, 800);

    return () => {
      clearTimeout(timer)
    }

  }, [search])

  return {
    suggestions,
    handleChangeSelector,
  }
}