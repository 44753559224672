import React from 'react'
import './blockOfContentTitle.css'
import { ReactComponent as Arrow } from '../../../assets/SVGs/ArrowRight.svg'

function BlockOfContentTitle({title, arrow}) {
  return (
    <div className='block-of-content-wrapper'>
      <div className='block-text'>
        <h1 className='text'>{title}</h1>
      </div>
      {arrow?
      <div className='block-arrow'>
        <Arrow className='arrow'/>
      </div>
      :
      <></>
      }
    </div>
  )
}

export default BlockOfContentTitle