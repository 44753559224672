import React, { useEffect } from 'react'
import { HashLoader } from 'react-spinners'
import { ReactComponent as CloseIcon } from '../../assets/SVGs/Close.svg'
import './eventTab.css'

function EventTab({event, menuWidth}) {

  useEffect(() => {
    const box = document.getElementById('event-box-wrapper')

    box.addEventListener('scroll', () => {
      if (box.scrollTop === 0) {
        box.classList.remove('scroll')
      } else if (!box.classList.contains('scroll')) {
        box.classList.add('scroll')
      } else {
        return
      }
    })

  }, [])

  return (
    <div id='event-box-wrapper' className='event-box' style={{'--l': `${menuWidth}px`}}>
      <div className='image-box'>
        {event?
        <div className='content'>
          <img src={event.img} alt='' className='image'/>
          <div className='text-box'>
            <div className='title-wrapper'>
              <h1 className='text'>{event.name}</h1>
            </div>
            <div className='date'>
              <h1 className='text'>9 декабря</h1>
              <h1 className='text'>6:20</h1>
            </div>
          </div>
        </div>
        :
        <div className='loader'>
          <HashLoader color="#6483FF" size={25} />
        </div>
        }
      </div>
      <div className='open-box'>
        <div className='content'> 
          <h1 className='text'>Cмотреть</h1>
          <div className='cancel-btn'>
            <CloseIcon className='close-icon'/>
          </div>
        </div>
      </div> 
      <div className='des-box'>
        {event?
        <div className='content'>
          <h1 className='header'>Что тут происходит?</h1>
          <div className='text-area-wrapper'>
            <h1 className='text-area'>{event.description}</h1>
          </div>
        </div>
        :
        <div className='loader'>
          <HashLoader color="#6483FF" size={25} />
        </div>
        }
      </div>
      <div className='des-box'>
        {event?
        <div className='content'>
          <h1 className='header'>Что тут происходит?</h1>
          <div className='text-area-wrapper'>
            <h1 className='text-area'>{event.description}</h1>
          </div>
        </div>
        :
        <div className='loader'>
          <HashLoader color="#6483FF" size={25} />
        </div>
        }
      </div>
      <div className='des-box'>
        {event?
        <div className='content'>
          <h1 className='header'>Что тут происходит?</h1>
          <div className='text-area-wrapper'>
            <h1 className='text-area'>{event.description}</h1>
          </div>
        </div>
        :
        <div className='loader'>
          <HashLoader color="#6483FF" size={25} />
        </div>
        }
      </div>
      <div className='adress-box'>
        {event?
        <div className='content'>
          <h1 className='text'>крутой адрес</h1>
        </div>
        :
        <div className='loader'>
          <HashLoader color="#6483FF" size={25} />
        </div>
        }
      </div>
    </div>
  )
}

export default EventTab