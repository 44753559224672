import React, { useEffect, useState } from 'react'
import Map from './components/map/map.jsx'
import Menu from './components/menu/menu.jsx'
import './index.css'
import { useAppOptions } from './useAppOptions.js'
import Typed from 'typed.js';
import { useLocation, useOutletContext, useParams } from 'react-router-dom'


function MainPage() {

  const {appParams, userPosition} = useAppOptions()
  const [menuWidth, setMenuWidth] = useState(500)
  const [load, setLoad] = useState({map: true, content: true})
  const [selectedEvent, setSelectedEvent] = useState()
  

  // const [theme] = useOutletContext();
  const theme = true
  
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['...'],
      typeSpeed: 150,
      loop: true,
      cursorChar: '',
      backDelay: 150,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);


  useEffect(() => {
    if (!selectedEvent) return

    const locate = window.location.search
    const urlParams = new URLSearchParams(locate)

    selectedEvent.hasOwnProperty('properties') ? urlParams.set('event', selectedEvent.properties.id) : urlParams.set('event', selectedEvent.id)

    const url = `?${urlParams.toString()}`

    window.history.pushState({}, '', url)

  }, [selectedEvent])


  useEffect(() => {
    const locate = window.location.search
    const urlParams = new URLSearchParams(locate)
    const eventData = urlParams.get('event')

    if (eventData) {
      setSelectedEvent({id: eventData})
    }

  }, [])

  useEffect(() => {
    console.log('selectedEvent', selectedEvent)
  }, [selectedEvent])

  return (
    <>
      <div id='app' className='screen'>
        <Menu setMenuWidth={setMenuWidth} menuWidth={menuWidth} setSelectedEvent={setSelectedEvent} selectedEvent={selectedEvent}/>
        <Map menuWidth={menuWidth} userPosition={userPosition} appParams={appParams} setLoad={setLoad} theme={theme} selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent}/>
      </div>
      {
        load.map || load.content?
        <div id='loading-screen' className='loading-screen'>
          <div className='content'>
            <div className='loading-state'>
              <h1 className='text'>loading</h1>
              <h1  className='text' ref={el}/>
            </div>
            <div className='des'>
              {load.map?
              <h1 className='text'>drawing map models</h1>
              :
              load.content?
              <h1 className='text'>request events data</h1>
              :
              <></>
              }
            </div>
          </div>
        </div>
        :
        <></>
      }
    </>
  )
}

export default MainPage