import { useEffect, useState } from "react"

export const useUserLocate = ({setCameraPosition, map, mapboxgl}) => {

  const [userMarker, setUserMarker] = useState()

  const changeZoom = (direction) => {
    let prevZoom = map.transform.zoom

    
    switch (direction) {
      case 'plus':
        map.flyTo({center: map.getCenter().toArray(), zoom: prevZoom + 1});
        break;
        
      case 'minus':
        map.flyTo({center: map.getCenter().toArray(), zoom: prevZoom - 1});
        break;

      default:
        break;
    }
  }

  const findMe = () => {
    const showMe = (e) => {
      setCameraPosition({lng: e.coords.longitude, lat: e.coords.latitude, event: false})

      const el = document.createElement('div');
      el.className = 'user-marker';
      el.innerHTML += `
        <div class="content" style="--img: url(${'https://storage.fileapi.ru/dvAvatar.JPG'});"></div>
        <div class="pulsar"></div>
      `

      const marker = new mapboxgl.Marker(el).setLngLat([e.coords.longitude, e.coords.latitude]).addTo(map);

      setUserMarker(marker)

    }
    navigator.geolocation.getCurrentPosition((e) => showMe(e))
  }

  const [btnState, setBtnState] = useState(false)

  const handleClick = () => {
    findMe()
    setBtnState(true)
    setTimeout(() => {
      setBtnState(false)
    }, 500);
  }


  useEffect(() => {
    // console.log('naviagator', navigator.geolocation)
    // spam check user position and re draw user mark
    
  }, [])

  return {
    changeZoom,
    findMe,
    btnState,
    handleClick,
  }
}