import { useEffect, useState } from "react"
import SearchTab from '../tabs/searchTab/searchTab.jsx'
import FeedTab from '../tabs/feedTab/feedTab.jsx'
import ProfileTab from '../tabs/profileTab/profileTab.jsx'



export const useNavOptions = ({selectedEvent, setSelectedEvent}) => {

  const [tab, setTab] = useState()

  const getTab = () => {
    const locate = window.location.search
    const urlParams = new URLSearchParams(locate)
    const tabTitle = urlParams.get('tab')
    if (tabTitle === null) {
      return 'feed'
    } return tabTitle
  }

  const [selectedName, setSelectedName] = useState({title: getTab(), item: undefined})

  useEffect(() => {
    const locate = window.location.search
    const urlParams = new URLSearchParams(locate)

    urlParams.set('tab', `${selectedName.title}`)

    const url = `?${urlParams.toString()}`
    
    window.history.pushState({}, '', url)

  }, [selectedName])

  const handleChangeToSearchTab = () => {
    document.querySelectorAll('.nav-btn-group .btn').forEach((item) => {
      item.attributes.name.textContent === 'search' ? item.click() : <></>
    })
  }

  const handleChangeToFeedTab = () => {
    document.querySelectorAll('.nav-btn-group .btn').forEach((item) => {
      item.attributes.name.textContent === 'feed' ? item.click() : <></>
    })
  }

  const selectItem = (arr, btn) => {
    arr.forEach((item) => {
      if (item.attributes.id.textContent == btn.attributes.id.textContent) {
        item.classList.add('selected')
      } else {
        item.classList.remove('selected')
      }
    })
  }

  useEffect(() => {
    const btns = document.querySelectorAll('.nav-btn-group .btn')
    const searchBox = document.getElementById('search-input-main')

    btns.forEach((item) => {
      item.addEventListener('click', (e) => {
        setSelectedName({title: item.attributes.name.textContent, item: item})
        selectItem(btns, item)
      })
    })

    searchBox?.addEventListener('focus', (e) => {
      handleChangeToSearchTab()
    })
  
  }, [])

  const getDataForSearchTab = () => {
    setTab(<SearchTab setSelectedEvent={setSelectedEvent} selectedEvent={selectedEvent}/>)
  }

  const getDataForFeedTab = () => {
    setTab(<FeedTab/>)
  }

  const getDataForProfileTab = () => {
    setTab(<ProfileTab/>)
  }

  useEffect(() => {
    if (selectedName === undefined) return
    
    switch (selectedName.title) {
      case 'search':
        getDataForSearchTab()
        break;

      case 'feed':
        getDataForFeedTab()
        break;

      case 'profile':
        getDataForProfileTab()
        break;

    
      default:
        break;
    }
  }, [selectedName])

  return {
    tab,
    handleChangeToSearchTab,
    selectedName,
    handleChangeToFeedTab
  }
}