import React, { useEffect, useState } from 'react'
import './signUp.css'
import { useSignUpForms } from './useSignUpFroms'
import waveleft from '../../../assets/IMGs/WaveLeft.png'
import { useParams } from 'react-router-dom'

function SignUp() {

  const {form} = useSignUpForms()

  const params = useParams()
  
  return (
    <div id='signUp' className='signUp-wrapper'>
      <div className='hello-box'>
        <div className='wave-wrapper-left'>
          <img src={waveleft} className='wave'/>
        </div>
      </div>
      <div className='signUp'>
        {form}
      </div>
    </div>
  )
}

export default SignUp