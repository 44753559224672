import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useEvents } from "../../globalHooks/useEvents";
import ActiveIcon from '../../assets/IMGs/ActiveIcon.png'
import ClusterIcon from '../../assets/IMGs/ClusterIcon.png'
import SelectedIcon from '../../assets/IMGs/SelectedIcon.png'
import ClubIcon from '../../assets/IMGs/ClubIcon.png'
import Basic from '../../assets/IMGs/BasicIcon.png'
import Passive from '../../assets/IMGs/PassiveIcon.png'

// create a bitmap images
const activeIcon = new Image()
activeIcon.src = ActiveIcon;

const partyIcon = new Image()
partyIcon.src = ClubIcon;

const eduIcon = new Image()
eduIcon.src = Basic;

const passiveIcon = new Image()
passiveIcon.src = Passive;

const clusterIcon = new Image()
clusterIcon.src = ClusterIcon;

const selectedIcon = new Image()
selectedIcon.src = SelectedIcon;



export const useMapOptions = ({menuWidth, userPosition, setSelectedEvent}) => {

  const [mapHeight, setMapHeight] = useState('100vh')
  const [map, setMap] = useState()
  const { updatePoints, points } = useEvents()
  const [currentCenter, setCurrentCenter] = useState()
  const [prevCenter, setPrevCenter] = useState()
  const [cameraPosition, setCameraPosition] = useState()
  const [lng, setLng] = useState()
  const [lat, setLat] = useState()

  // add images to map 

  useEffect(() => {
    if (map === undefined) return
    map.addImage('active', activeIcon)
    map.addImage('cluster', clusterIcon)
    map.addImage('selected', selectedIcon)
    map.addImage('passive', passiveIcon)
    map.addImage('edu', eduIcon)
    map.addImage('party', partyIcon)
  }, [map])

  // map fucntions 
  const renderDotsOfEvents = (points, map) => { 
    const geojson = {
      type: 'FeatureCollection',
      features: [
  
      ]
    };

    for (const dot of points) {
      const newMonument = 
      {
        type: "Feature",
        properties: {
          id: dot.id,
          time: dot.timestamp_start,
          paid: dot.paid,
          type: dot.type,
          icon: dot.type,
        },
        geometry: {
          type: "Point",
          coordinates: [dot.lng, dot.lat, 0]
        }
      }
      geojson.features.push(newMonument)
    }

    addMonumentsToMap(map, geojson)
  }

  const selectFeature = (e, map) => {

    let data = map.getSource('earthquakes')._data.features 
    let points = map.queryRenderedFeatures(e.point, {
      layers: ['unclustered-point']
    })

    if (points.length) {
      data.map(function(item, index) {
      if (item.properties.id === points[0].properties.id) {
        data[index].properties.icon = 'selected'
        setSelectedEvent(data[index])
      }
      else
        data[index].properties.icon = data[index].properties.type
    })


    const geojson = {
      type: 'FeatureCollection',
      features: data
    };

    map.getSource('earthquakes').setData(geojson)
    }
  }

  const selectCurrentFeature = (point, map) => { 

    clearFeature(map)
    setSelectedEvent(point)
    let data = map.getSource('earthquakes')._data.features 

    for (let feature of data) {
      if (feature.properties.id === point.id) {
        feature.properties.icon = 'selected'
      }
    }

    const geojson = {
      type: 'FeatureCollection',
      features: data
    };
    map.getSource('earthquakes').setData(geojson)
    setSelect(undefined)
  }

  const goTo = () => {
    if (lng === undefined || lat === undefined) return
    map.easeTo({
      center: [lng, lat],
      zoom: 15,
    });
  }

  const clearFeature = (map) => {
    setSelectedEvent(undefined)
    let data = map.getSource('earthquakes')._data.features 
    data.map(function(item, index) {
      if (item.properties.icon === 'selected') {
        data[index].properties.icon = data[index].properties.type
        const geojson = {
          type: 'FeatureCollection',
          features: data
        };
        map.getSource('earthquakes').setData(geojson)
      }
    })

  }

  const getVisibleMapViewport = () => {
    const corners = map.transform.cameraFrustum.bounds.getCorners()
    const center = new mapboxgl.MercatorCoordinate(map.transform.cameraFrustum.bounds.center[0], map.transform.cameraFrustum.bounds.center[1], map.transform.cameraFrustum.bounds.center[2]).toLngLat().toArray()
    const lnglatCorners = []
    const requestDate = []

    for (const corner of corners) {
      const nullIsland = new mapboxgl.MercatorCoordinate(corner[0], corner[1], corner[2]).toLngLat().toArray();
      lnglatCorners.push(nullIsland)
    }

    for (let i = 0; i <= 3; i++) {
      requestDate.push(lnglatCorners[i])
    }

    return {
      lngMax: requestDate[1][0] + 0.75,
      lngMin: requestDate[0][0] - 0.75,
      latMax: requestDate[1][1] + 0.75,
      latMin: requestDate[2][1] - 0.75,
      center,
    }
  }

  const get = () => {
    updatePoints(getVisibleMapViewport())
  }

  const checkRequestData = (prev, current) => {

    const center = {
      lng: getVisibleMapViewport().center[0] - 0.2,
      lat: getVisibleMapViewport().center[1] - 0.2,
    }
    

    if (prev === undefined) {
      get()
      setPrevCenter(getVisibleMapViewport())
      return
    } 

    let res = false
    if (prev.lngMax <  center.lng || prev.lngMin > center.lng) {
      res = true
    }

    if (prev.latMax < center.lat || prev.latMin > center.lat) {
      res = true
    }

    if (res) {
      updatePoints(getVisibleMapViewport())
      setPrevCenter(getVisibleMapViewport())
    }
  }

  // add styles to layers
  const addMonumentsToMap = (map, data) => {

    if (map.getSource('earthquakes') === undefined) {
      map.addSource('earthquakes', {
        type: 'geojson',
        data: data,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      map.addLayer({
        id: 'clusters',
        type: 'symbol',
        source: 'earthquakes',
        filter: ['has', 'point_count'],
        layout: {
          'icon-image': 'cluster',
          'icon-size': 0.075,
        }
      });

      map.addLayer({
        id: 'unclustered-point',
        source: 'earthquakes',
        type: 'symbol',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': 0.06,
        }
      })
      


    } else {
      map.getSource('earthquakes').setData(data)
    }

    // inspect a cluster on click
    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      const clusterId = features[0].properties.cluster_id;
      map
        .getSource('earthquakes')
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom + 0.1,
          });
        });
    });

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.
    map.on('click', 'unclustered-point', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      selectFeature(e, map)
      map.easeTo({
        center: coordinates,
        zoom: 17
      });
      setCameraPosition({lng: coordinates[0], lat: coordinates[1]})
      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
    });

    map.on('mouseenter', 'clusters', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', () => {
      map.getCanvas().style.cursor = '';
    });


    map.on('mouseout', () => {
      map.off('click', 'clusters');
      map.off('click', 'unclustered-point');
      map.off('mouseenter', 'clusters',);
      map.off('mouseleave', 'clusters');
    })

  }
  
  // effects
  useEffect(() => {
    if (map === undefined) return
    map.on('load', () => {
      setCurrentCenter(getVisibleMapViewport().center)
    })
    map.on('moveend', () => {
      setCurrentCenter(getVisibleMapViewport().center)
    })
    map.on('dragend', () => {
      setCurrentCenter(getVisibleMapViewport().center)
    })
    map.on('zoomend', () => {
      setCurrentCenter(getVisibleMapViewport().center)
    })
  }, [map])

  useEffect(() => {
    if (currentCenter === undefined) return
    checkRequestData(prevCenter, currentCenter)
  }, [currentCenter])

  // render all map source
  useEffect(() => {
    if (points === undefined) return
    renderDotsOfEvents(points, map)
  }, [points])

  // camera settings 
  const [select, setSelect] = useState()

  useEffect(() => {
    if (map === undefined) return
    if (cameraPosition === undefined) return

    const range = (window.innerWidth - 950 * 0.75 / 2) / (111_139 / 0.30) 

    map.easeTo({
      center: [cameraPosition.lng - range, cameraPosition.lat],
      zoom: 15,
    });

    const center = map.getCenter()
    if (cameraPosition.event === true) {
      if (center.lng > Number(cameraPosition.lng) - 2 && center.lng < Number(cameraPosition.lng) + 2 && center.lat > Number(cameraPosition.lat) - 2 && center.lat < Number(cameraPosition.lat) + 2){
        selectCurrentFeature(cameraPosition.data, map)
      } else {
        setSelect({pos: cameraPosition.data, map: map})
      }
    }

  }, [cameraPosition])

  const [prevEvents, setPrevEvents] = useState()
  useEffect(() => {
    if (points === undefined) return
    if (prevEvents === undefined) return setPrevEvents(points);

    if (prevEvents[0].id !== points[0].id) select? selectCurrentFeature(select.pos, select.map) : <></>

  }, [points])

  // map resize
  useEffect(() => {
    if (map === undefined) return
    const resize = setInterval(() => {
      map.resize()
    }, 0.1);

    return () => {
      clearInterval(resize)
    }
  }, [menuWidth])

  // off alll listeners
  useEffect(() => {
    if (map === undefined) return
    map.on('click', () => clearFeature(map))
    map.on('mouseout', () => {
      map.off('click')
    })
  }, [map])

  return {
    mapHeight,
    setMap,
    get,
    points,
    selectCurrentFeature,
    cameraPosition,
    setLng,
    setLat,
    goTo,
    setCameraPosition,
    cameraPosition,
  }
}