import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import ErrorPage from './pages/404errorPage/404errorPage.jsx';
import {
  BrowserRouter,
  Route,
  Routes,
  useRouteError
} from "react-router-dom";
import './index.css'
import MainPageLayout from './mainSceenLayout';
import { isMobile } from 'react-device-detect';
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import AuthPage from './pages/auth/authPage.jsx';
import { QueryClient, QueryClientProvider } from 'react-query';


if (isMobile) {
  if (!window.location.href.includes('m.'))  {
    let href = window.location.href.replace('//', '//m.')
    window.location.href = href
  }
}

const store = createStore({
  authName:'_auth',
  authType:'cookie',
  cookieDomain: window.location.hostname,
  // cookieSecure: window.location.protocol === 'https:',
  cookieSecure: false,
});


const container = document.getElementById('root');
const root = createRoot(container);
const queryClient = new QueryClient()


if (window.location.hostname === 'localhost') {
  root.render(
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        store={store}
      >
        <BrowserRouter>
          <Routes>
            <Route path={'/auth' } element={<AuthPage/>}/>
            {/* <Route element={<AuthOutlet fallbackPath='/auth?action=signUp'/>}> */}
            <Route path='/' element={<MainPageLayout/>}/>
            {/* </Route> */}
            <Route path='*' element={<ErrorPage/>}/>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
  
} else {
  root.render(
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path={'/auth' } element={<AuthPage/>}/>
            <Route path='/' element={<MainPageLayout/>}/>
            <Route path='*' element={<ErrorPage/>}/>
          </Routes>
        </BrowserRouter>
    </QueryClientProvider>
  )
}


