import React, { useEffect, useState } from 'react'
import { ReactComponent as Active } from '../../../assets/SVGs/BallIcon.svg'
import { ReactComponent as Passive } from '../../../assets/SVGs/RelaxIcon.svg'
import { ReactComponent as More } from '../../../assets/SVGs/TripleDot.svg'
import { ReactComponent as Basic } from '../../../assets/SVGs/BookIcon.svg'
import './activityTypeCard.css'

function ActivityTypeCard({text, type}) {

  const [icon, setIcon] = useState()

  useEffect(() => {
    switch (type) {
      case 'active':
        setIcon(
          <div className='icon-box'>
            <div className='icon-wrapper'>
              <Active width={25} height={25} className='icon'/>
            </div>
          </div>
        )
        break;

      case 'passive':
        setIcon(
          <div className='icon-box'>
            <div className='icon-wrapper'>
              <Passive width={25} height={25} className='icon'/>
            </div>
          </div>
        )
        break;

        case 'basic':
          setIcon(
            <div className='icon-box'>
              <div className='icon-wrapper'>
                <Basic width={25} height={25} className='icon'/>
              </div>
            </div>
          )
          break;
          
          case 'more':
            setIcon(
              <div className='icon-box'>
                <div className='icon-wrapper'>
                  <More width={25} height={25} className='icon'/>
                </div>
              </div>
            )
            break;
    
      default:
        break;
    }
  }, [])

  return (
    <div className='activity-tag-wrapper'>
      <div className='active-content'>
        <h1 className='text'>{text}</h1>
        <h1 className='count'>234 мероприятия</h1>
      </div>
      {icon}
    </div>
  )
}

export default ActivityTypeCard