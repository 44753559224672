import { useEffect, useState } from "react"

export const useAppOptions = () => {

  const [userPosition, setUserPosition] = useState()
  const [appParams, setAppParams] = useState([])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((e) => setUserPosition(e.coords))
  }, [])

  const handleRemoveItem = name => {
    setAppParams(appParams.filter(item => item.name !== name))
  } 

  // useEffect(() => {
  //   if (!window) return
  //   Object.defineProperty(window, 'f_on_navigation', {
  //     get: function () {
  //       return setAppParams(prev => [...prev, 'lngLatZoom'])
  //     }
  //   });
  //   Object.defineProperty(window, 'f_off_navigation', {
  //     get: function () {
  //       return handleRemoveItem('lngLatZoom')
  //     }
  //   });
  // }, [window])


  return {
    userPosition, 
    setUserPosition, 
    appParams
  }
}